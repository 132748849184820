import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/(user)/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--font-poppins\",\"display\":\"swap\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/(user)/layout.tsx\",\"import\":\"Orbitron\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"variable\":\"--font-orbitron\",\"display\":\"swap\"}],\"variableName\":\"orbitron\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/(user)/layout.tsx\",\"import\":\"Rubik\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"variable\":\"--font-rubik\",\"display\":\"swap\"}],\"variableName\":\"rubik\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/neonsun_white.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Buttons/ThemeButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Cookie/CookiesConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Layout/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Provider"] */ "/vercel/path0/src/components/Layout/Provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/hotjar/HotjarSnippet.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
